import {
  mapState
} from 'vuex';
export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      langVisible: false,
      navVisible: false,
      paylink:''
    }
  },
  computed: {
    ...mapState({
      locales: state => state.locales
    }),
    langname() {
      let res = this.$store.state.locales.filter(item => item.language === this.$store.state.locale);
      return res[0].langname;
    }
  },
  watch: {
    '$route'(res) {
      this.$store.commit('SET_NOTICESTYPE', location.pathname.includes('notices'));
    }
  },
  mounted() {
    this.paylink = `https://pay.supersus.io/region?from=${window.location.href}`;
    // 隐藏语言菜单逻辑
    let menuTarget = this.$refs.language;
    document.addEventListener('click', (e) => {
      if (!menuTarget.contains(e.target)) {
        this.langVisible = false;
      }
    });
    this.$store.commit('SET_NOTICESTYPE', location.pathname.includes('notices'));
  },
  methods: {
    handleClickNav() {
      this.navVisible = false;
      this.$store.commit('SET_FLOATBANNER', true);
    },
    // 切换语言
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('SET_LANG', lang);
      this.$cookies.set('lang', lang);
      this.langVisible = false;
    },
    // 显示菜单
    showLanglist() {
      this.langVisible = !this.langVisible;
    }
  }
}
