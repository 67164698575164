import { render, staticRenderFns } from "./m_index.vue?vue&type=template&id=47f2331b&scoped=true"
import script from "./m_index.vue?vue&type=script&lang=js"
export * from "./m_index.vue?vue&type=script&lang=js"
import style0 from "./m_index.vue?vue&type=style&index=0&id=47f2331b&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f2331b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/builds/sR3RmDikN/0/gwt/sites/supersus/components/navigation/index.vue').default,InsideBanner: require('/builds/sR3RmDikN/0/gwt/sites/supersus/components/inside/banner.vue').default,Footer: require('/builds/sR3RmDikN/0/gwt/sites/supersus/components/footer/index.vue').default,Float: require('/builds/sR3RmDikN/0/gwt/sites/supersus/components/float/float.js').default})
